<template>
	<edit-template ref="edit" style="padding: 24rem;box-sizing: border-box" @confirm="save('form')" @cancel="open(disabled)" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <!-- 大表单 -->
    <div>
      <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="100rem">
        <el-form-item label="选择校区" prop="school_id">
          <!-- <el-select :popper-append-to-body="false" v-model="form.school_id" placeholder="请选择校区" :disabled="disabled">
						<el-option v-for="item in campusArr" :label="item.school_name" :value="parseInt(item.id)"></el-option>
					</el-select> -->
          {{ form.school_name }}
        </el-form-item>

        <el-form-item label="选择楼宇" prop="building_id">
          <el-select v-model="form.building_id" :disabled="disabled" :popper-append-to-body="false" placeholder="请选择楼宇" @change="changeBuilding">
            <el-option v-for="(item, index) in buildingArr" :label="item.dimicile_buliding_name" :value="Number(item.id)">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择楼层" prop="storey">
          <el-select v-model="form.storey" :disabled="disabled" :popper-append-to-body="false" placeholder="请选择楼层">
            <el-option v-for="item in floorArr" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="教室名称" prop="addr_name">
          <el-input v-model="form.addr_name" maxlength="20" placeholder="请输入教室名称"></el-input>
        </el-form-item>

      </el-form>
    </div>
    <!-- 底部按钮 -->
    
  
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
    return {
      disabled: false,
      form: {storey: '', school_id: '', addr_name: '', building_id: ''},
      campusArr: [],
      buildingArr: [],
      floorArr: [],
      subjectsArr: [],
      rules: {
        school_id: [{required: true, message: '请选择校区', trigger: 'change'}],
        building_id: [{required: true, message: '请选择楼宇', trigger: 'change'}],
        storey: [{required: true, message: '请选择楼层', trigger: 'change'}],
        addr_name: [{required: true, message: '请输入辅导地点', trigger: 'change'}],
      },
      options: []
    }
  },

  methods: {
    save(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          let {building_id, storey, addr_name, place_id} = this.form
          let data = {building_id, storey, addr_name, place_id}
          this.$_axios2.post('api/place/edit-place', data, {logic: 1}).then((res) => {
            this.$message({
              type: 'success',
              message: '修改成功!'
            })
            this.$router.back()
          })
        }
      })
    },
    open(val) {
      if (val) {
        this.$router.back()
      } else
        this.$confirm('是否取消编辑', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.back()
        })
    },
    changeBuilding(val) {
      this.floorArr = []
      this.form.storey = ''
      this.buildingArr.forEach((item, index) => {
        if (val == item.id) {
          for (let index = item.floor_min; index <= item.floor_max; index++) {
            if (index !== 0) this.floorArr.push(index)
          }
        }
      })
    }
  },

  created() {
    this.$_axios2.get('api/place/place-detail?place_id=' + this.$route.query.id).then(res => {
      this.form = Object.assign(res.data.data, {place_id: Number(this.$route.query.id)})
      this.$_axios2.get('api/1v1/place/get-building?school_id=' + this.form.school_id + '&type=2').then(res => {
        this.buildingArr = res.data.data
        this.buildingArr.forEach(item => {
          if (this.form.building_id == item.id) {
            for (let index = item.floor_min; index <= item.floor_max; index++) {
              this.floorArr.push(index)
            }
          }
        })
      })
    })

    this.$_axios.get('site/school').then(res => {
      this.campusArr = res.data.data
    })
    // this.$_axios.get('site/fist-subject').then(res => {
    //   this.subjectsArr = res.data.data
    // })
    if (this.$route.query.type == 0) {
      this.disabled = true
      for (const key in this.rules) {
        this.rules[key][0].required = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__label {
  font-size: 16rem;
  font-weight: 600;
  color: #333333;
}
</style>
